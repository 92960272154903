exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-builds-build-id-tsx": () => import("./../../../src/pages/builds/[build_id].tsx" /* webpackChunkName: "component---src-pages-builds-build-id-tsx" */),
  "component---src-pages-buy-appraisals-index-tsx": () => import("./../../../src/pages/buy-appraisals/index.tsx" /* webpackChunkName: "component---src-pages-buy-appraisals-index-tsx" */),
  "component---src-pages-contact-request-appraisals-tsx": () => import("./../../../src/pages/contact/request-appraisals.tsx" /* webpackChunkName: "component---src-pages-contact-request-appraisals-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-cookies-index-tsx": () => import("./../../../src/pages/cookies/index.tsx" /* webpackChunkName: "component---src-pages-cookies-index-tsx" */),
  "component---src-pages-create-tsx": () => import("./../../../src/pages/create.tsx" /* webpackChunkName: "component---src-pages-create-tsx" */),
  "component---src-pages-disclaimer-tsx": () => import("./../../../src/pages/disclaimer.tsx" /* webpackChunkName: "component---src-pages-disclaimer-tsx" */),
  "component---src-pages-garage-tsx": () => import("./../../../src/pages/garage.tsx" /* webpackChunkName: "component---src-pages-garage-tsx" */),
  "component---src-pages-graphs-tsx": () => import("./../../../src/pages/graphs.tsx" /* webpackChunkName: "component---src-pages-graphs-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-landing-index-tsx": () => import("./../../../src/pages/landing/index.tsx" /* webpackChunkName: "component---src-pages-landing-index-tsx" */),
  "component---src-pages-manual-how-to-find-desktop-pc-components-tsx": () => import("./../../../src/pages/manual/how-to-find-desktop-pc-components.tsx" /* webpackChunkName: "component---src-pages-manual-how-to-find-desktop-pc-components-tsx" */),
  "component---src-pages-manual-index-tsx": () => import("./../../../src/pages/manual/index.tsx" /* webpackChunkName: "component---src-pages-manual-index-tsx" */),
  "component---src-pages-manual-using-your-appraisal-tsx": () => import("./../../../src/pages/manual/using-your-appraisal.tsx" /* webpackChunkName: "component---src-pages-manual-using-your-appraisal-tsx" */),
  "component---src-pages-parts-case-fan-part-id-tsx": () => import("./../../../src/pages/parts/case_fan/[part_id].tsx" /* webpackChunkName: "component---src-pages-parts-case-fan-part-id-tsx" */),
  "component---src-pages-parts-case-part-id-tsx": () => import("./../../../src/pages/parts/case/[part_id].tsx" /* webpackChunkName: "component---src-pages-parts-case-part-id-tsx" */),
  "component---src-pages-parts-cpu-cooler-part-id-tsx": () => import("./../../../src/pages/parts/cpu_cooler/[part_id].tsx" /* webpackChunkName: "component---src-pages-parts-cpu-cooler-part-id-tsx" */),
  "component---src-pages-parts-cpu-part-id-tsx": () => import("./../../../src/pages/parts/cpu/[part_id].tsx" /* webpackChunkName: "component---src-pages-parts-cpu-part-id-tsx" */),
  "component---src-pages-parts-gpu-part-id-tsx": () => import("./../../../src/pages/parts/gpu/[part_id].tsx" /* webpackChunkName: "component---src-pages-parts-gpu-part-id-tsx" */),
  "component---src-pages-parts-motherboard-part-id-tsx": () => import("./../../../src/pages/parts/motherboard/[part_id].tsx" /* webpackChunkName: "component---src-pages-parts-motherboard-part-id-tsx" */),
  "component---src-pages-parts-operating-system-part-id-tsx": () => import("./../../../src/pages/parts/operating_system/[part_id].tsx" /* webpackChunkName: "component---src-pages-parts-operating-system-part-id-tsx" */),
  "component---src-pages-parts-psu-part-id-tsx": () => import("./../../../src/pages/parts/psu/[part_id].tsx" /* webpackChunkName: "component---src-pages-parts-psu-part-id-tsx" */),
  "component---src-pages-parts-ram-part-id-tsx": () => import("./../../../src/pages/parts/ram/[part_id].tsx" /* webpackChunkName: "component---src-pages-parts-ram-part-id-tsx" */),
  "component---src-pages-parts-storage-part-id-tsx": () => import("./../../../src/pages/parts/storage/[part_id].tsx" /* webpackChunkName: "component---src-pages-parts-storage-part-id-tsx" */),
  "component---src-pages-privacy-index-tsx": () => import("./../../../src/pages/privacy/index.tsx" /* webpackChunkName: "component---src-pages-privacy-index-tsx" */),
  "component---src-pages-sell-tsx": () => import("./../../../src/pages/sell.tsx" /* webpackChunkName: "component---src-pages-sell-tsx" */),
  "component---src-pages-terms-index-tsx": () => import("./../../../src/pages/terms/index.tsx" /* webpackChunkName: "component---src-pages-terms-index-tsx" */)
}

