import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import fetch from 'isomorphic-fetch';

// instructions to do so for header from https://www.gatsbyjs.com/plugins/gatsby-plugin-apollo/
// code from https://www.apollographql.com/docs/react/networking/authentication/

const httpLink = createHttpLink({
    uri: process.env.GATSBY_BACKEND_URL, // 'https://backend-35gbjl6sja-uc.a.run.app/graphql', // 'http://localhost:3000/graphql'
    fetch
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `${token}` : "",
    }
  }
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
});

export default client;